<template>
  <div class="login-container">
    <div class="logo">
      <img v-if="!isIstore" style="height: 60px" src="../../assets/sfclogo.png" />
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      label-position="left"
      @submit.native.prevent
    >
      <h3 class="title">

        {{ isIstore ? '信息化管理平台' : '三态股份信息化管理平台' }}
      </h3>
      <el-row>
        <div class="qr" v-if="qrUrl">
          <img :src="qrUrl" alt="qr" />
        </div>
        <el-form-item prop="googleAuthCode" :error="errorMsg">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            v-model="loginForm.googleAuthCode"
            name="googleAuthCode"
            auto-complete="on"
            placeholder="身份验证器验证码"
            @input="replaceWord"
            @keyup.enter.native.prevent="handleLogin"
            :controls="false"
          />
        </el-form-item>
        <div class="clear"></div>
        <el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleLogin"
            >提交</el-button
          >
        </el-form-item>
        <a href="https://p1oc8b34mgk.feishu.cn/docx/VgBcdt43jokSylx1PzecSp2Wn0g" target="_blank" style="font-size:14px;color: #c6c8d1;cursor: pointer;">
          <i class='el-icon-warning-outline' style="margin-right: 5px;"></i>登录操作指引</a>
      </el-row>
    </el-form>
    <div class="footer-bottom">
      Copyright © 2021 <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">{{ editionStr }}</a> All right reserved
    </div>
  </div>
</template>
<script>
import { getQr } from '@/api/special-login'
import { toLink } from '@/utils/index'
export default {
  data() {
    return {
      redirect:'',
      qrUrl: '',
      isCode: false,
      loginForm: {
        googleAuthCode: '',
        username:'',
        password:'',
        googleAuthSecret:''
      },
      loginRules: {
        googleAuthCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      errorMsg: '',
      loading: false,
      pwdType: 'password',
      editionStr: '三态股份信息化管理平台',
    };
  },
  watch:{
    $route: {
      handler: function(route) {
        console.log(route,'route')
        if (route.query && route.query.redirect) {
          this.redirect = route.query.redirect
        }
      },
      immediate: true
    },
  },
  computed: {
    isIstore() {
      return this.$store.getters.isIstore;
    },
  },
  mounted() {
    this.code = this.$store.getters.account.code;
    let flag = this.$store.getters.account.username && this.$store.getters.account.password;
    if(flag){
      if(this.code === '0000013'){
        const obj = {username:this.$store.getters.account.username};
        getQr(obj).then(res=>{
          if(res.code === '000000'){
            this.qrUrl = res.data.qr;
            this.loginForm.googleAuthSecret = res.data.secret;
          }
        })
      }
    }else{
      this.qrUrl = '';
      this.$router.push('/login');
    }
  },
  methods: {
    replaceWord(){
      this.loginForm.googleAuthCode = this.loginForm.googleAuthCode.replace(/[^\d]/g, '');
    },
    showPwd() {
      this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let account = this.$store.getters.account;
          if(account.username && account.password){
            let obj = {
              "username":this.$store.getters.account.username,
              "password":this.$store.getters.account.password,
              "googleAuthSecret": this.loginForm.googleAuthSecret,
              "googleAuthCode":this.loginForm.googleAuthCode  
            }
            this.$store.dispatch('qrLogin',obj).then((res)=>{
              this.loading = false;
              if(res.code === '000000'){
                if(this.redirect.indexOf('jumpServer') > -1){
                    let object = {
                      //userId: res.data.userInfo.userId,
                      // systemCode: item.usId,
                      //username: res.data.userInfo.name,
                      // ip: Cookies.get('userIp'),
                      token: res.data.token
                    }
                    object = JSON.stringify(object)
                    const Base64 = require('js-base64').Base64
                    const urlCode = Base64.encode(object)
                    const url = this.redirect.split('?')[0] 
                    window.location.href = url+'core/auth/login/new/?code='+urlCode
                    return
                }
                toLink().then(path=>{
                  if(this.redirect){
                  window.location.href = this.redirect;
                  }else{
                    this.$router.push({path});
                  }
                })
              }else{
                this.$message({
                  message: res.msg,
                  type: 'error',
                });
              }
            }).finally(()=>{
              this.loading = false;
            })
          }else{
            this.$message({
              message: '请先登录',
              type: 'error',
            });
            this.$router.push('/login');
          }
        } else {
          return false;
        }
      });
    },
    checkUser() {
      this.errorMsg = '';
      if (this.loginForm.username) {
        this.$store
          .dispatch('checkUser', this.loginForm.username)
          .then((res) => {
            if (res) {
              this.errorMsg = res;
            }
          });
      }
    },
    changeLog() {
      this.isCode = !this.isCode;
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
$bg: #2d3a4b;
$light_gray: #eee;

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 520px;
    max-width: 100%;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
    .code-tab {
      // height: 248px;
      // background: #ccc;
      width: 100%;
      text-align: center;
      h5 {
        font-size: 16px;
        color: #2f2c2c;
      }
    }
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .title {
    font-size: 26px;
    font-weight: 400;
    color: $light_gray;
    margin: 0px auto 40px auto;
    text-align: center;
    font-weight: bold;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .clear {
    clear: both;
  }
}
.logo {
  position: absolute;
  display: flex;
  height:60px;
  line-height: 60px;
  left: 5px;
  top: 10px;
  vertical-align: middle;
  color:#fff;
}
.footer-bottom {
  line-height: 44px;
  flex-grow: 1;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  font-weight: 500;
  color: #c6c8d1;
  text-align: center;
  width: 100%;
  border-top: 1px solid #3f3b5c;
}
.qr{
  text-align: center;
  margin-bottom: 20px;
  img{
    width: 200px;
    height: 200px;
  }
}
.myinput{
  border: none;
  outline: none;
  width: 80%;
  height: 52px;
  padding-left: 15px;
  background-color: transparent;
  color: #fff;
}
</style>